import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import LawPage from '../components/LawPage';

const LawFamilyPage: React.FC = () => (
  <Layout id="lawFamily">
    <SEO title="FamilyLaw" />
    <LawPage
      law="LawFamily"
      title="fieldLawFamily"
      serviceTitle="fieldLawFamilyTitle"
      lawTexts={[
        'fieldLawFamilyOne',
        'fieldLawFamilyTwo',
        'fieldLawFamilyThree',
        'fieldLawFamilyFour',
        'fieldLawFamilyFive',
        'fieldLawFamilySix',
        'fieldLawFamilySeven',
        'fieldLawFamilyEight',
      ]}
    />
  </Layout>
);

export default LawFamilyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
